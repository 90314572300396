import React from "react";
import H1 from "../../Atomics/Typography/H1";
import { FeatureWithImage } from "./FeatureWithImage";

const FeaturesWithImage = ({
	header,
	subheader,
	sections,
	isHighlighted,
	lang,
}) => {
	return (
		<section
			className={` mx-auto w-11/12 lg:w-10/12 xl:w-9/12 py-10 lg:py-16 xl:py-20 ${
				isHighlighted && "bg-[#FAFBFC]"
			}`}
		>
			<H1
				header={header}
				subheader={subheader}
				className="w-11/12 md:w-10/12 lg:w-8/12   mx-auto text-center"
			/>
			{sections &&
				sections.map((card, index) => {
					return (
						<FeatureWithImage
							key={index}
							features={card.Features}
							image={card.image}
							imageLeft={card.imageLeft}
							lang={lang}
						/>
					);
				})}
		</section>
	);
};
export default FeaturesWithImage;

import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import HeroSection from "../components/Home/Hero";
import LogoSlider from "../components/Home/LogoSlider";
import FeaturesCards from "../components/Home/FeaturesCards";
import FeaturesWithImage from "../components/Home/FeaturesWithImage";
import VideoSection from "../components/Home/VideoSection";
import FaqSection from "../components/Home/FaqSection";
import Cta from "../components/Cta";
import ImageSlider from "../components/About/ImageSlider";
import CardImage from "../components/About/CardImage";
import Heroes from "../components/About/Heroes";
import CardsWithIconSection from "../components/Contact/CardWithIconSection";
import ContactForm from "../components/Contact/ContactForm";
import Careers from "../components/Careers";
import Gallery from "../components/Solution/Gallery";
import SectionHeader from "../components/SectionHeader";
import SolutionSection from "../components/Solution/SolutionSection";
import SEO from "../components/SEO";

export const pageQu = graphql`
	query MyQuery($id: String!) {
		sanityPage(id: { eq: $id }) {
			slug {
				current
			}
			i18n_lang
			_rawContent(resolveReferences: { maxDepth: 10 })
			title
			seoTitle
			seoImage {
				asset {
					url
				}
			}
		}
	}
`;

const Page = ({ data }) => {
	const page = data.sanityPage;
	const lang = page.i18n_lang;
	const content = (page._rawContent || []).map((c, i) => {
		let el = null;
		switch (c._type) {
			case "heroSection":
				el = (
					<HeroSection
						// text={
						// 	lang === "ar" ? (
						// 		<>
						// 			<h2 className="font-bold text-2xl md:text-3xl  rtl:lg:leading-normal mb-2">
						// 				ندعم الفاتورة الإلكترونية والإيصال الإلكتروني
						// 			</h2>
						// 			<p className="font-semibold text-xl   rtl:lg:leading-normal text-[#8A94A6]">
						// 				يعد برنامج الفاتورة الإلكترونية وبرنامج الإيصال الإلكتروني
						// 				المدعومين بالبرنامج المالي والمحاسبي وبرنامج المخازن من أكثر
						// 				البرامج سهولة ودقة بالربط مع مصلحة الضرائب المصرية والسعودية
						// 			</p>
						// 		</>
						// 	) : (
						// 		<>
						// 			<h2 className="font-bold text-2xl rtl:lg:leading-normal mb-4">
						// 				We support the E-Invoice & E-Receipt integration
						// 			</h2>
						// 			<p className="font-semibold text-xl   rtl:lg:leading-normal text-[#8A94A6]">
						// 				We integrate with Egyptian & Saudi Arabian tax authorities
						// 				and our applications are back-ended by the financial and
						// 				supply chain management applications to streamline all the
						// 				required processes..{" "}
						// 			</p>
						// 		</>
						// 	)
						// }
						key={c._key}
						{...c}
					/>
				);
				break;
			case "logosSlider":
				el = <LogoSlider key={c._key} {...c} />;
				break;
			case "erpCards":
				el = <FeaturesCards key={c._key} {...c} />;
				break;
			case "featuresWithImage":
				el = <FeaturesWithImage lang={lang} key={c._key} {...c} />;
				break;
			case "videoSection":
				el = <VideoSection key={c._key} {...c} />;
				break;
			case "faqSection":
				el = <FaqSection key={c._key} {...c} />;
				break;
			case "cta":
				el = <Cta key={c._key} {...c} />;
				break;
			case "cardImage":
				el = <CardImage key={c._key} {...c} />;
				break;
			case "imageSlider":
				el = <ImageSlider key={c._key} {...c} />;
				break;
			case "heroes":
				el = <Heroes key={c._key} {...c} />;
				break;
			case "cardsIcon":
				el = <CardsWithIconSection key={c._key} {...c} />;
				break;
			case "contactForm":
				el = <ContactForm lang={lang} key={c._key} {...c} />;
				break;
			case "careersTable":
				el = <Careers key={c._key} {...c} />;
				break;
			case "gallery":
				el = <Gallery key={c._key} {...c} />;
				break;
			case "sectionHeader":
				el = <SectionHeader key={c._key} {...c} />;
				break;
			case "solutionSection":
				el = <SolutionSection lang={lang} key={c._key} {...c} />;
				break;
			default:
				el = null;
		}

		return el;
	});

	return (
		<>
			<SEO title={page?.seoTitle || page?.title} />
			<Layout lang={lang}>{content}</Layout>
		</>
	);
};

export default Page;

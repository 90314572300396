import React from "react";
import { RevealAnimation } from "../../Atomics/RevealAnimation";
import Section from "../../Sections";
import { Card } from "./Card";

const FeaturesCards = ({ cards, header, subheader, button, isHighlighted }) => {
	return (
		<Section
			header={header}
			subheader={subheader}
			headerClassName="text-center w-11/12 md:w-10/12 lg:w-8/12  2xl:w-6/12 mx-auto "
			button={button}
			isHighlighted={isHighlighted}
		>
			<div className="w-10/12 lg:w-9/12 mx-auto flex flex-col items-center">
				<div className="grid lg:grid-cols-2 gap-8">
					{cards &&
						cards.map((card, index) => {
							return (
								<RevealAnimation key={index}>
									<Card
										image={card.image}
										title={card.title}
										text={card.text}
									/>
								</RevealAnimation>
							);
						})}
				</div>
			</div>
		</Section>
	);
};
export default FeaturesCards;

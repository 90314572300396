import React from "react";
import { motion } from "framer-motion";
import { FigureImage } from "../../Atomics/FigureImage";
import { RevealAnimation } from "../../Atomics/RevealAnimation";

export const Card = ({ image, title, text }) => {
	return (
		<div className="p-8 xl:p-12 bg-[#FAFBFC] rounded-2xl flex flex-col">
			<div className=" mb-auto">
				<FigureImage
					node={image}
					className=" w-full h-full object-cover aspect-auto"
					figureClassName="mb-8 aspect-auto "
				/>
			</div>
			<div className="mt-auto">
				<motion.h3
					initial="hidden"
					whileInView="visible"
					viewport={{ once: true }}
					transition={{ duration: 1 }}
					variants={{
						visible: { opacity: 1, y: 0 },
						hidden: { opacity: 0, y: 25 },
					}}
					className=" mb-5 text-2xl xl:text-3xl font-bold "
				>
					{title}
				</motion.h3>
				<motion.p
					initial="hidden"
					whileInView="visible"
					viewport={{ once: true }}
					transition={{ duration: 1 }}
					variants={{
						visible: { opacity: 1, y: 0 },
						hidden: { opacity: 0, y: 25 },
					}}
					className="text-[#8A94A6] font-medium lg:text-lg "
				>
					{text}
				</motion.p>
			</div>
		</div>
	);
};

import React from "react";
import { FigureImage } from "../../Atomics/FigureImage";
import Marquee from "react-fast-marquee";

const LogoSlider = ({ logosTitle, logos, isHighlighted }) => {
	return (
		<section className={`py-8 ${isHighlighted && "bg-[#FAFBFC]"}`} dir="ltr">
			<div className="mx-auto">
				{logosTitle && (
					<p className="mb-12 text-2xl font-bold text-center uppercase">
						{logosTitle}
					</p>
				)}
				<Marquee className="flex " autoFill pauseOnClick duration={500}>
					{logos && (
						<ul className="flex">
							{logos &&
								logos.map(({ image }) => {
									return (
										<li
											key={image._key}
											className=" flex  px-4 w-32 h-32 md:w-40 md:h-40 lg:w-48 xl:w-56"
										>
											<FigureImage
												node={image}
												figureClassName="object-contain rounded-2xl flex items-center"
												className="object-cover rounded-2xl "
											/>
										</li>
									);
								})}
						</ul>
					)}
				</Marquee>
			</div>
		</section>
	);
};
export default LogoSlider;

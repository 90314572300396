import classNames from "classnames";
import React from "react";
import { FigureImage } from "./Atomics/FigureImage";
import H1 from "./Atomics/Typography/H1";

const SectionHeader = ({ header, subheader, image, isHighlighted }) => {
	return (
		<section
			className={classNames(
				"flex flex-col items-center py-32 w-11/12 xl:w-10/12 mx-auto",
				isHighlighted && "bg-[#FAFBFC]"
			)}
		>
			<H1 className=" text-center" header={header} subheader={subheader} />
			<FigureImage node={image} className="w-full" />
		</section>
	);
};
export default SectionHeader;
